import React from "react";
import {
  Box,
  Flex,
  VStack,
  Text,
  Avatar,
  Badge,
  useColorModeValue,
  IconButton,
  Icon,
  HStack,
} from "@chakra-ui/react";
import {
  FiGithub,
  FiTwitter,
  FiLinkedin,
  FiMail,
  FiPhone,
} from "react-icons/fi";

interface ProfileCardProps {
  name: string;
  profession: string;
  avatarUrl: string;
  coverUrl: string;
  bio: string;
  twitter?: string;
  github?: string;
  linkedin?: string;
  email?: string;
  phone?: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  profession,
  avatarUrl,
  coverUrl,
  bio,
  twitter,
  github,
  linkedin,
  email,
  phone,
}) => {
  const textColor = useColorModeValue("gray.700", "gray.300");

  return (
    <Box
      backgroundColor={"#F7F8FC"}
      maxW="350"
      w="full"
      overflow="hidden"
      margin={{ base: "auto", md: 0 }}
      rounded="lg"
    >
      <Box
        h="200"
        w="full"
        bgImage={coverUrl}
        bgSize="cover"
        bgPosition="center"
        p={6}
      />
      <Flex justifyContent="center" mt={-10}>
        <Avatar
          src={avatarUrl}
          size="2xl"
          borderWidth={4}
          borderColor="white"
        />
      </Flex>
      <VStack align="center" spacing={2} mt={6} px={6}>
        <Text fontSize="2xl" fontWeight="bold" color={textColor}>
          {name}
        </Text>
        <Badge fontSize="md" colorScheme="blue" p={1} borderRadius="md">
          {profession}
        </Badge>
        <Text textAlign="center" color={textColor}>
          {bio}
        </Text>
      </VStack>
      <HStack justifyContent="center" mt={4} px={6} my={6}>
        {github && (
          <IconButton
            as="a"
            href={`https://github.com/${github}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="GitHub"
            icon={<Icon as={FiGithub} />}
          />
        )}
        {twitter && (
          <IconButton
            as="a"
            href={`https://twitter.com/${twitter}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
            icon={<Icon as={FiTwitter} />}
          />
        )}
        {linkedin && (
          <IconButton
            as="a"
            href={`https://linkedin.com/in/${linkedin}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
            icon={<Icon as={FiLinkedin} />}
          />
        )}
        {email && (
          <IconButton
            as="a"
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Email"
            icon={<Icon as={FiMail} />}
          />
        )}
        {phone && (
          <IconButton
            as="a"
            href={`tel:${phone}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Phone"
            icon={<Icon as={FiPhone} />}
          />
        )}
      </HStack>
    </Box>
  );
};

export default ProfileCard;

import {
  Box,
  ChakraProvider,
  theme,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";
import ProfileCard from "./components/ProfileCard";
import Tabs from "./components/Tabs";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        maxW={1200}
        width="100%"
        margin="0 auto"
        justifyContent="center"
        alignItems="center"
        p={6}
      >
        <ProfileCard
          name="Appy Sharma"
          profession="Frontend Engineer"
          avatarUrl="https://media.licdn.com/dms/image/C4E03AQGzSb7y-GeNqw/profile-displayphoto-shrink_800_800/0/1609820851481?e=1684368000&v=beta&t=3yGN7phDxAbfboThinWJxHgJ3XWYTVfIGbm-VHopejU"
          coverUrl="https://pbs.twimg.com/profile_banners/34633928/1677351355/1500x500"
          bio="I am a frontend engineer who loves to build things for the web."
          twitter="appyofficial"
          github="appyofficial"
          linkedin="appyofficial"
          email="appyeu@gmail.com"
          phone="+46760481990"
        />
      </Flex>
    </ChakraProvider>
  );
};

/*

<Box maxW={1200} width="100%" margin="0 auto" p={6}>
        <Grid
          templateColumns={isWideScreen ? "1fr 2fr" : "1fr"}
          gap={6}
          width="100%"
        >
          <GridItem>
            <ProfileCard
              name="Appy Sharma"
              profession="Frontend Engineer"
              avatarUrl="https://media.licdn.com/dms/image/C4E03AQGzSb7y-GeNqw/profile-displayphoto-shrink_800_800/0/1609820851481?e=1684368000&v=beta&t=3yGN7phDxAbfboThinWJxHgJ3XWYTVfIGbm-VHopejU"
              coverUrl="https://pbs.twimg.com/profile_banners/34633928/1677351355/1500x500"
              bio="I am a frontend engineer who loves to build things for the web."
              twitter="appyofficial"
              github="appyofficial"
              linkedin="appyofficial"
              email="appyeu@gmail.com"
              phone="+46760481990"
            />
          </GridItem>
          <GridItem>
            <Tabs />
            <Box>
              <iframe
                title="Newsletter"
                src="https://1de039b1.sibforms.com/serve/MUIEAA0Nvi_fltHJQ2k7zfmuxWh05O_eQpjPuq2QZ70_RTX8f9h2XFdITfdeDPXu_YPCnWpT4hhM_FXakwaM9J-wHCmWwGUxZUwXfd0eNSi-X1tg5H46tdFWwKsupe0SNt6uS-j42UJ0mdpfLaM1qk08W0Qw5mjwN6sfVq0QFoktWccAUU4vvjfwtOWQ02Uc3ip_bAcIxPeS88v0"
                allowFullScreen
                scrolling="no"
              ></iframe>
            </Box>
          </GridItem>
        </Grid>
      </Box>*/
